import React from 'react'

import Layout from '../components/layout'
import Seo from '../components/seo'

import PageHero from '../components/pageHero'

import ResourceCard from '../components/resourceCard'

const Resources = () => (
	<Layout>
		<Seo
			title='Ресурсы'
			descripeion='Интернет-ресурсы для создателей и владельцев сайтов. Мы собрали на одной странице сервисы, которыми пользуемся сами или рекомендуем нашим клиентам. Сервисы проверенные, свою задачу выполняют. Попробуй, если тебе это зайдет, то и хорошо.'
		/>

		<PageHero
			title='Ресурсы'
			description='Здесь ты найдешь интернет-ресурсы, необходимые для твоих сайтов. Здесь подобраны только самые подходящие сервисы. Какими-то из них мы пользуемся сами, другими пользуются наши клиенты. Поэтому это все проверено на себе.'
			button=''
		/>

		<ResourceCard ></ResourceCard>

	</Layout>
)

export default Resources